// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Plan.module.css */
.Plan_planContainer__PalPb {

    justify-content: space-around;
    padding: 20px;
    background: linear-gradient(180deg, #000000, #64017a 60%, #27083f 70%, #321F45);
    flex-wrap: wrap;
    /* Ensure items wrap to the next line */
    color: #fff;
    border-top: 1px solid #00ffff;
    text-align: center;
    margin: 0 auto;
}

.Plan_planItem__Zy5MB {
    text-align: center;
    padding: 15px 25px;
    background-color: rgba(88, 1, 108, .5);
    margin-bottom: 20px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(82, 255, 228, 0.7);
    border: 2px solid rgba(0, 255, 255, 0.7);
    border-radius: 8px;

    /* Add space between stacked items */
}

.Plan_planIcon__SLjos {
    font-size: 2.5rem;
    color: var(--asterius-blue);
    margin-top: 10px;
}

.Plan_planHeaderH2__4WRjf {
    margin: 10px 0 5px 0;
}

.Plan_planHeader__af2WF {
    font-size: 1.5rem;
    margin: 10px 0;
}

.Plan_planSubtext__lwy9M {
    font-size: 20px;
    color: #fff;
}

/* Media query for tablet size and below */
@media (min-width: 768px) {
    .Plan_planItem__Zy5MB {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        /* Make each item take full width */
    }
}`, "",{"version":3,"sources":["webpack://./src/css/Plan.module.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;;IAEI,6BAA6B;IAC7B,aAAa;IACb,+EAA+E;IAC/E,eAAe;IACf,uCAAuC;IACvC,WAAW;IACX,6BAA6B;IAC7B,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,sCAAsC;IACtC,mBAAmB;IACnB,+EAA+E;IAC/E,wCAAwC;IACxC,kBAAkB;;IAElB,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,WAAW;AACf;;AAEA,0CAA0C;AAC1C;IACI;QACI,UAAU;QACV,iBAAiB;QACjB,kBAAkB;QAClB,mCAAmC;IACvC;AACJ","sourcesContent":["/* Plan.module.css */\n.planContainer {\n\n    justify-content: space-around;\n    padding: 20px;\n    background: linear-gradient(180deg, #000000, #64017a 60%, #27083f 70%, #321F45);\n    flex-wrap: wrap;\n    /* Ensure items wrap to the next line */\n    color: #fff;\n    border-top: 1px solid #00ffff;\n    text-align: center;\n    margin: 0 auto;\n}\n\n.planItem {\n    text-align: center;\n    padding: 15px 25px;\n    background-color: rgba(88, 1, 108, .5);\n    margin-bottom: 20px;\n    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(82, 255, 228, 0.7);\n    border: 2px solid rgba(0, 255, 255, 0.7);\n    border-radius: 8px;\n\n    /* Add space between stacked items */\n}\n\n.planIcon {\n    font-size: 2.5rem;\n    color: var(--asterius-blue);\n    margin-top: 10px;\n}\n\n.planHeaderH2 {\n    margin: 10px 0 5px 0;\n}\n\n.planHeader {\n    font-size: 1.5rem;\n    margin: 10px 0;\n}\n\n.planSubtext {\n    font-size: 20px;\n    color: #fff;\n}\n\n/* Media query for tablet size and below */\n@media (min-width: 768px) {\n    .planItem {\n        width: 50%;\n        margin-left: auto;\n        margin-right: auto;\n        /* Make each item take full width */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"planContainer": `Plan_planContainer__PalPb`,
	"planItem": `Plan_planItem__Zy5MB`,
	"planIcon": `Plan_planIcon__SLjos`,
	"planHeaderH2": `Plan_planHeaderH2__4WRjf`,
	"planHeader": `Plan_planHeader__af2WF`,
	"planSubtext": `Plan_planSubtext__lwy9M`
};
export default ___CSS_LOADER_EXPORT___;
