import { useEffect } from 'react';

/* 
This is if you want it to animate out and animate when you scroll back up the page 

const useScrollAnimation = ( sectionClass: string, textClass: string, animationClass: string ) => {
    useEffect( () => {
        const handleScroll = () => {
            const sections = document.querySelectorAll( sectionClass );
            const triggerOffset = window.innerHeight / 2;

            sections.forEach( ( section ) => {
                const rect = section.getBoundingClientRect();
                const text = section.querySelector( textClass );

                if ( text ) {
                    if ( rect.top < triggerOffset && rect.bottom > triggerOffset ) {
                        text.classList.add( animationClass );
                    } else {
                        text.classList.remove( animationClass );
                    }
                }
            } );
        };

        window.addEventListener( 'scroll', handleScroll );
        return () => {
            window.removeEventListener( 'scroll', handleScroll );
        };
    }, [ sectionClass, textClass, animationClass ] );
}; */

const useScrollAnimation = ( sectionClass: string, textClass: string, animationClass: string ) => {
    useEffect( () => {
        const handleScroll = () => {
            const sections = document.querySelectorAll( sectionClass );
            const triggerOffset = window.innerHeight / 1.4; // decrease this number for it to start animating sooner when you scroll down the page
            const bottomTriggerOffset = window.innerHeight * .5; 

            sections.forEach( ( section ) => {
                const rect = section.getBoundingClientRect();
                const text = section.querySelector( textClass );

                if ( text && !text.classList.contains( 'animated' ) ) { // Check if already animated
                    if ( ( rect.top < triggerOffset && rect.bottom > triggerOffset ) || ( window.innerHeight + window.scrollY >= document.body.offsetHeight - bottomTriggerOffset ) ) {
                        text.classList.add( animationClass, 'animated' ); // Add 'animated' class
                    }
                }
            } );
        };

        window.addEventListener( 'scroll', handleScroll );
        return () => {
            window.removeEventListener( 'scroll', handleScroll );
        };
    }, [ sectionClass, textClass, animationClass ] );
};

export default useScrollAnimation;