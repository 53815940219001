// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(90deg, #a0fdfd, #00ffff);
    /* background: linear-gradient(90deg, #000000, #64017a); */
    transform-origin: 0%;
    z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/ProgressBar/ProgressBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,WAAW;IACX,oDAAoD;IACpD,0DAA0D;IAC1D,oBAAoB;IACpB,aAAa;AACjB","sourcesContent":[".progress-bar {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    height: 5px;\n    background: linear-gradient(90deg, #a0fdfd, #00ffff);\n    /* background: linear-gradient(90deg, #000000, #64017a); */\n    transform-origin: 0%;\n    z-index: 9999;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
